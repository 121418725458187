import { t } from "@lingui/macro";
import { message, Modal } from "antd";
import { first, isArray, isEmpty, isObject, keys, startCase } from "lodash";

// recursive function to map errors to form field.
const parseInLineErrors = async (error, form) => {
  if (isEmpty(error)) {
    return;
  }

  const iterate = (errorObject, cb, parentKey = []) => {
    if (!isEmpty(errorObject)) {
      Object.keys(errorObject).forEach((i) => {
        parentKey.push(i);

        switch (true) {
          case isArray(errorObject):
            errorObject.map((o, j) => {
              parentKey.push(j);

              iterate(o, cb, parentKey);
            });
            break;
          case isObject(errorObject):
            iterate(errorObject[i], cb, parentKey);
            break;
          default:
            cb(i, errorObject[i]);
        }
      });
    }
  };

  const cb = (key, error) => {
    try {
      form.setFields({
        name: key,
        errors: error,
      });
    } catch (error) {
      console.error("could not set error:", key, error);
    }
  };

  iterate(error, cb);
};

const getFormFieldErrors = (data) => {
  const fieldErrors = [];
  Object.keys(data).forEach((key) => {
    fieldErrors.push({ name: key, errors: data[key] });
  });

  return fieldErrors;
};

const parseCreateOrderError = (error, form, setFilledTabs) => {
  let nonFieldError = true;

  keys(error).forEach((key) => {
    if (key === "tasks_data") {
      nonFieldError = false;
      error[key].forEach((taskError, index) => {
        if (!isEmpty(taskError)) {
          const firstFailedField = keys(taskError)?.[0];

          // TODO might have to implement recursion in the future when object nesting goes over two
          if (!isArray(taskError[firstFailedField])) {
            const nestedFieldKey = keys(taskError[firstFailedField])?.[0];

            // check if nested field value is string
            if (isArray(taskError[firstFailedField][nestedFieldKey])) {
              if (firstFailedField === "metafields") {
                form.setFields([
                  {
                    name: [firstFailedField, nestedFieldKey],
                    errors: taskError[firstFailedField][nestedFieldKey],
                  },
                ]);
                setFilledTabs &&
                  setFilledTabs((currTabs) => ({
                    ...currTabs,
                    metafields: {
                      title: "Custom fields",
                      error: true,
                    },
                  }));
              } else {
                form.setFields([
                  {
                    name: ["tasks_data", index, firstFailedField, nestedFieldKey],
                    errors: taskError[firstFailedField][nestedFieldKey],
                  },
                ]);

                setFilledTabs &&
                  setFilledTabs((currTabs) => ({
                    ...currTabs,
                    tasks_data: {
                      title: t`Waypoints`,
                      text: form.getFieldValue("tasks_data").length,
                      error: true,
                    },
                  }));
              }
            } else {
              // currently we don't handle errors where error object is nested more than two times. For catching these error we have to implement recursive error handling
              console.error("Task creation error parser fails due to deeply nested error object.");
            }
          } else {
            /* Special case to handle assignee field error */
            if (firstFailedField === "assignee") {
              form.setFields([
                {
                  name: firstFailedField,
                  errors: taskError[firstFailedField],
                },
              ]);
            } else {
              form.setFields([
                {
                  name: ["tasks_data", index, firstFailedField],
                  errors: taskError[firstFailedField],
                },
              ]);
            }
          }
        }
      });
    }
  });

  if (nonFieldError) {
    parseErrorResponse(error, true);
  }
};

const flattenObj = (obj, nkey = "", res = {}) => {
  for (let key in obj) {
    let keys = nkey ? nkey + "_" + key : key;
    if (typeof obj[key] == "object") {
      flattenObj(obj[key], keys, res);
    } else {
      res[keys] = obj[key];
    }
  }
  return res;
};

const parseErrorResponse = (err, show = false) => {
  let errMsg: string | object;

  switch (typeof err?.response?.data) {
    case "string":
      errMsg = err?.response?.data;
      break;
    default:
      const msg = flattenObj(err?.response?.data);
      errMsg = (
        <div>
          {Object.keys(msg).map((msgKey, i) => {
            if (msgKey === `non_field_errors_${i}`) {
              return <div key={i}>{msg[msgKey]}</div>;
            }

            return (
              <div key={i}>
                {startCase(msgKey)}: {msg[msgKey]}
              </div>
            );
          })}
        </div>
      );
  }

  if (show) {
    Modal.error({
      title: t`Something went wrong`,
      content: <>{errMsg}</>,
    });
    return;
  }

  return errMsg;
};
const parseErrorResponseAsString = (err) => {
  let errMsg = "";

  if (typeof err === "string") {
    errMsg = err;
  }
  if (typeof err === "object") {
    errMsg = JSON.stringify(err, null, 2);
  }

  return errMsg;
};

const parseErrorToFormFields = (err, form) => {
  keys(err).forEach((key) => {
    if (!form.getFieldValue(key)) {
      message.error(`${err[key][0]}`);
    } else {
      form.setFields([{ name: key, errors: err[key] }]);
    }
  });
};

const parseUserAndAccountError = (err, form) => {
  // handle account error
  if (err.account) {
    keys(err.account).forEach((key) => {
      form.setFields([{ name: ["account", key], errors: err?.account[key] }]);
    });
  }

  // handle user error
  if (err.user) {
    keys(err.user).forEach((key) => {
      form.setFields([{ name: ["user", key], errors: err?.user[key] }]);
    });
  }
};

const responseToFieldErrors = (data, form) => {
  // Reset old errors
  form.getFieldsError().forEach((field) => {
    if (field.errors.length) {
      form.setFields([{ name: field.name, errors: [] }]);
    }
  });

  // Map new errors
  keys(data).forEach((key) => {
    // If field in form -> set field error
    // Else -> display message
    if (keys(form.getFieldsValue()).includes(key)) {
      form.setFields([{ name: key, errors: data[key] }]);
    } else {
      message.error(first(data[key]));
    }
  });
};

export enum ERROR_LOG_LEVEL {
  WARNING = "warning",
  "FATAL" = "fatal",
}

// TODO: Format and ship errors on demand to sentry
const shipErrorToSentry = (level: ERROR_LOG_LEVEL, prefix: string, errMsg: string, errorObject: object = null) => {
  console.error(`${level}: ${prefix}: ${errMsg} , ${errorObject}`);
};

export {
  getFormFieldErrors,
  parseCreateOrderError,
  parseErrorResponse,
  parseInLineErrors,
  parseErrorToFormFields,
  parseUserAndAccountError,
  responseToFieldErrors,
  shipErrorToSentry,
  parseErrorResponseAsString,
};
