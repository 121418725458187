import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import Head from "next/head";
import Script from "next/script";
import { IntercomProvider } from "react-use-intercom";

import "styles/base.scss"; // TODO: should retire
import AntdApplicationConfig from "components/antd-config";
import { AppContextProvider } from "contexts/app";
import useInitApplication from "hooks/init-app";
import { Profiler } from "react";
import StatsJs from "hooks/stats";

const GOOGLE_TAG_MANAGER_ID = "GTM-MTGQ2JF";
const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;

const GSMtasks = ({ Component, pageProps, err }) => {
  const { accountId, language, userId, token } = pageProps;

  const pageLoading = useInitApplication();

  pageProps = { ...pageProps, pageLoading };

  return (
    <>
      <Profiler id="gsmtasks-profiler" onRender={undefined}>
        {/* Google Tag Manager - Global base code */}
        <Script src={`https://www.googletagmanager.com/gtag/js?id=AW-983637248`} strategy="afterInteractive" />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          (function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', '${GOOGLE_TAG_MANAGER_ID}');
          `}
        </Script>
        <Head>
          <title>GSMTasks</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
        </Head>
        <AntdApplicationConfig language={language}>
          <I18nProvider i18n={i18n}>
            <IntercomProvider appId={INTERCOM_APP_ID} autoBoot autoBootProps={{ alignment: "left" }}>
              <AppContextProvider accountId={accountId} language={language} token={token} userId={userId}>
                <Component {...pageProps} err={err} />
                <StatsJs />
              </AppContextProvider>
            </IntercomProvider>
          </I18nProvider>
        </AntdApplicationConfig>
        <StatsJs />
      </Profiler>
    </>
  );
};

export default GSMtasks;
/* * https://ant.design/docs/react/customize-theme use this as reference later modify ant d theme.
 * TODO: resolve and clean console for errors and warnings. */
