import React, {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { datadogLogs } from "@datadog/browser-logs";
import { t } from "@lingui/macro";
import { message } from "antd";
import { get, isEmpty, keyBy, startCase } from "lodash";
import NextRouter from "next/router";
import { useIntercom } from "react-use-intercom";
import useSWR, { KeyedMutator, SWRConfig } from "swr";
import useDeepCompareEffect from "use-deep-compare-effect";

import ErrorPage from "components/error/default";
import { MapsWrapper } from "components/map";
import { MAP_MODE } from "components/map/controls/darkmode";
import NotificationBar from "components/notification";
import httpClient, { HttpClient } from "hooks/http-client";
import useIsDeviceMobile from "hooks/mobile";
import usePageTitle from "hooks/page-title";
import { useRedirectDeActiveAccounts } from "hooks/redirect";
import { AccountRole, Configuration, LastTimeLocations } from "types/type";
import { httpStatusCodes } from "utils/status-codes-utils";
import useFetchAll from "hooks/fetch-all";

const ACCOUNT_ROLE_LIMIT = 1000;
const MAP_CONFIGURATION_KEY = "app.gsmtasks.com-map-settings";
const BILLING_PAGE_URL = "/settings/subscriptions";

const ENVIRONMENT = process.env.NODE_ENV;
export const STRIPE_PUBLISHABLE_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY;
export const ROUTING_API = process.env.NEXT_PUBLIC_API_ROUTING_API;
export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
export const VAT_COMPLY_API = process.env.NEXT_PUBLIC_VAT_COMPLY_API_URL;
export const LOG_FOR = process.env.NEXT_PUBLIC_LOG_FOR?.split(",") || [];
const DATADOG_KEY = process.env.NEXT_PUBLIC_DATADOG_KEY;
const protectedAdminRoutes = ["/settings/api-keys", "/settings/subscriptions", "/settings/webhooks"];

type MapData = {
  lat: number;
  lng: number;
  zoom: number;
};

export interface MapConfig {
  mapStyle: MAP_MODE;
  setMapStyle: Dispatch<SetStateAction<string>>;
  center: MapData;
  setCenter: Dispatch<SetStateAction<MapData>>;
  bounds: { lat: number; lng: number }[];
  setBounds: Dispatch<SetStateAction<{ lat: number; lng: number }[]>>;
  boundsAsURL?: MutableRefObject<string>;
  toggleMapStyle: () => void;
}

interface IAppContext {
  readonly configuration: Configuration;
  appData:
    | {
        assignees: {
          data: {
            getUserById: (user) => AccountRole;
            activeWorkers: AccountRole[];
            accountRoles: AccountRole[];
            last_time_locations?: LastTimeLocations[];
          };
          mutate: KeyedMutator<any>;
        };
        readonly accountId: string;
        readonly userId: string;
        readonly language: string;
        readonly displayName: string;
        readonly isDeviceMobile: boolean;
        mapConfig: MapConfig;
        readonly activeRoutes?: { [key: string]: any };
        readonly isUserOnlyAWorker?: boolean;
      }
    | undefined;
  readonly httpClient: HttpClient | null;
}

const AppContext = createContext<IAppContext>({ configuration: null, appData: undefined, httpClient: httpClient() });

const AppContextProvider = ({ accountId, children, language, token, userId }: any) => {
  usePageTitle(startCase(window.location.pathname));

  const [mapStyle, setMapStyle] = useState<MAP_MODE>();
  const [mapCenter, setMapCenter] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const mapURLBounds = useRef("");
  const [mapsLoaded, setMapsLoaded] = useState(false);

  const client = httpClient(token, language);

  const { data: configuration } = useSWR(token && `${window.location.origin}/api/configurations`, {
    fetcher: client.bareFetcher,
    revalidateOnFocus: false,
  });

  let url = `/account_roles/?account=${accountId}&ordering=display_name,created_at&page_size=${ACCOUNT_ROLE_LIMIT}`;

  const {
    records: assignees = [],
    mutate: mutateAssignees,
    loading,
  } = useFetchAll(accountId && configuration && url, client.swrInfiniteFetcher);

  const isAdmin = get(configuration, "permissions.is_admin") === true;
  const isManager = get(configuration, "permissions.is_manager") === true;
  const isWorker = get(configuration, "permissions.is_worker") === true;
  const isUserOnlyAWorker = configuration ? !isAdmin && !isManager && isWorker : undefined;
  const shouldFetchRoutes = isUserOnlyAWorker && accountId && configuration;

  const { data: activeRoutes } = useSWR(
    shouldFetchRoutes && `/routes/?account=${accountId}&state__in=active&page_size=1000`,
    {
      fetcher: client.swrFetcher,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    setMapStyle(getMapSettings(MAP_MODE.LIGHT));
  }, []);

  const usersById = keyBy(assignees, "user");
  const notifications = configuration?.notifications || [];
  const isDeviceMobile = useIsDeviceMobile();

  const data = {
    assignees: {
      data: {
        accountRoles: assignees,
        activeWorkers: assignees?.filter((assignee) => assignee.is_worker === true && assignee.state === "active"),
        getUserById: (user) => {
          return usersById[user];
        },
      },
      mutate: mutateAssignees,
    },
    accountId,
    userId,
    displayName: configuration?.user?.display_name,
    language,
    isDeviceMobile,
    isUserOnlyAWorker,
    activeRoutes: keyBy(activeRoutes, "url"),
    mapConfig: {
      done: mapsLoaded,
      mapStyle,
      setMapStyle,
      center: mapCenter,
      setCenter: setMapCenter,
      bounds: mapBounds,
      setBounds: setMapBounds,
      boundsAsURL: mapURLBounds,
      toggleMapStyle: useCallback(() => {
        setMapStyle((state: MAP_MODE) => {
          const v = state === MAP_MODE.LIGHT ? MAP_MODE.DARK : MAP_MODE.LIGHT;
          if (!isEmpty(v)) {
            localStorage && localStorage.setItem(MAP_CONFIGURATION_KEY, JSON.stringify(v));
            return v;
          }
        });
      }, []),
    },
  };

  useRedirectDeActiveAccounts(configuration);

  const { update } = useIntercom();

  const intercomProps = {
    company: { companyId: configuration?.account?.id, name: configuration?.account?.name },
    email: configuration?.user?.email,
    userId: configuration?.user?.id,
    name: configuration?.user?.display_name,
    userHash: configuration?.user?.intercom_hash,
  };

  useDeepCompareEffect(() => {
    if (configuration?.user) {
      update(intercomProps);
    }
  }, [{ configuration }]);

  useEffect(() => {
    if (LOG_FOR.includes(configuration?.user?.url)) {
      // TEMPORARY USED FOR LOGGING USER ACTIVITY.
      datadogLogs.init({
        clientToken: DATADOG_KEY,
        site: "datadoghq.com",
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        service: "gsmtasks-web-app",
      });
      datadogLogs.setGlobalContext({
        env: ENVIRONMENT === "production" ? "gsmtasks-live" : "gsmtasks-dev",
        "usr.id": configuration?.user?.id,
        "usr.email": configuration?.user?.email,
        "usr.name": configuration?.user?.display_name,
        "gsmtasks.account_id": configuration?.account?.id,
        version: process.env.NEXT_PUBLIC_COMMIT_SHA,
      });
    }
  }, [
    configuration?.account?.id,
    configuration?.user?.email,
    configuration?.user?.id,
    configuration?.user?.url,
    configuration?.user?.username,
  ]);

  if (get(configuration, "permissions.is_admin") !== true) {
    if (protectedAdminRoutes.includes(window.location.pathname)) {
      return <ErrorPage code={httpStatusCodes.UNAUTHORIZED} />;
    }
  }

  if (window.location.pathname !== BILLING_PAGE_URL) {
    const billing = configuration?.billing;
    if (billing && ["stripe", "braintree"].includes(billing?.billing_method) && billing?.has_payment_method === false) {
      message.warn(t`No payment method found. Please provide one to continue using GSMtasks`);
      NextRouter.push(BILLING_PAGE_URL);
    }
  }

  // Load Google Maps once on application load
  return (
    <MapsWrapper
      callback={(v) => {
        if (v === "SUCCESS") {
          setMapsLoaded(true);
        }
      }}
    >
      <AppContext.Provider value={{ configuration, appData: data, httpClient: client }}>
        <SWRConfig
          value={{
            fetcher: client.swrFetcher,
            shouldRetryOnError: false,
            revalidateOnFocus: false,
          }}
        >
          {notifications && <NotificationBar notifications={notifications} />}
          {children}
        </SWRConfig>
      </AppContext.Provider>
    </MapsWrapper>
  );
};

const getMapSettings = (defaultValue: MAP_MODE) => {
  if (localStorage) {
    return JSON.parse(localStorage.getItem(MAP_CONFIGURATION_KEY)) || defaultValue;
  }
  return defaultValue;
};

const useAppContext = () => useContext(AppContext);

const useHttpClient = () => useContext(AppContext)?.httpClient as HttpClient;

export { AppContextProvider, useAppContext, useHttpClient };
