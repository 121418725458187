import { useEffect, useRef, useState } from "react";

import { t } from "@lingui/macro";
import { message } from "antd";

import useInterval from "hooks/interval";
import { httpStatusCodes } from "utils/status-codes-utils";

const onHandleRetries = (error, retryCount, setRetryCount, setTimedOut) => {
  message.warning(t`An error occurred while fetching tasks, tasks states maybe inconsistent.`);

  // disable retries for 404 response
  if (error.status === httpStatusCodes.NOT_FOUND || error.status === httpStatusCodes.UNAUTHORIZED) return;

  // end after trying up to 10 times
  if (retryCount >= 10) return;

  // Incremental retires.
  setRetryCount(retryCount + 1);
  setTimedOut(2 ** 1000 * retryCount);
};

const useTasksFetcher = (url: string | undefined, client: any, reloadInterval: number = 0) => {
  const [data, setData] = useState<any>(undefined);
  const [timedOut, setTimedOut] = useState<number>(200);
  const [retryCount, setRetryCount] = useState<number>(0);
  const prevURL = useRef(null);

  const fetcher = () => {
    client(url).then((response) => {
      if (response.status !== httpStatusCodes.OK) {
        onHandleRetries(response, retryCount, setRetryCount, setTimedOut);
        return;
      }

      if (response) {
        setData({ ...response });
        prevURL.current = url;
      } else {
        setData({});
      }
    });
  };

  useInterval(fetcher, reloadInterval === 0 ? null : reloadInterval);

  useEffect(() => {
    if (!url) {
      return;
    }

    const loader = () =>
      setTimeout(() => {
        fetcher();
      }, timedOut);

    if (client && prevURL.current !== url && reloadInterval === 0) {
      fetcher();
    }

    return () => {
      clearTimeout(loader());
    };
  }, [url, timedOut, reloadInterval]);

  const reset = () => {
    setData(undefined);
  };

  return { data, reset };
};

export default useTasksFetcher;
